@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
    font-family: Graphik;
    src: url('../fonts/Graphik/Graphik-Bold-Desktop.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: Graphik;
    src: url('../fonts/Graphik/Graphik-Medium-Desktop.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: Graphik;
    src: url('../fonts/Graphik/Graphik-Regular-Desktop.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: Graphik;
    src: url('../fonts/Graphik/Graphik-Semibold-Desktop.otf') format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: 'Roboto Flex';
    src: url('../fonts/RobotoFlex/static/RobotoFlex/RobotoFlex-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto Flex';
    src: url('../fonts/RobotoFlex/RobotoFlex-VF.ttf') format('truetype supports variations'),
        url('../fonts/RobotoFlex/RobotoFlex-VF.ttf') format('truetype-variations');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
}
